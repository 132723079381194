<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-grid"/> {{caption}}
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        hover
        striped
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="p_trees"
        :fields="fields"
        :items-per-page="small ? 20 : 8"
        :dark="dark"
        pagination
        table-filter
        items-per-page-select
        sorter
      >
        <template #created_at="{item}">
          <td>
              {{formatDate(item.created_at)}}
          </td>     
        </template>
        <template #updated_at="{item}">
          <td>
              {{formatDate(item.updated_at)}}
          </td>     
        </template>
        <template #picture="{item}">
          <td>
              <a :href="'https://drive.google.com/file/d/' + item.path + '/view'" target="_blank">view</a>
          </td>     
        </template>
        <template #no_trees="{item}">
            <td>
                {{addComma(item.no_trees)}}
            </td>
        </template>
          <template #date="{item}">
            <td>
                {{formatDate1(item.date)}}
            </td>
        </template>
      </CDataTable>

    </CCardBody>
  </CCard>
</template>

<script>
export default {
  name: 'Table',
  props: {
    p_trees: Array,
    fields: {
      type: Array,
      default () {
        return ['no_trees','place','date','picture','created_at']
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  methods: {

    formatDate(date) {
      return moment
        .utc(date, "YYYY-MM-D hh:mm:ss")
        .locale("en-us")
        .local()
        .format("MMM D YYYY hh:mm A");
    },
    formatDate1(date) {
      return moment
        .utc(date, "YYYY-MM-D")
        .locale("en-us")
        .local()
        .format("MMM D YYYY");
    },
    addComma(amount) {
      let num_parts = amount.toString().split(".");
      num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      return num_parts.join(".");
    },

  }
}
</script>
