<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-grid"/> {{caption}}
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        hover
        striped
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="list_heis"
        :fields="fields"
        :items-per-page="small ? 20 : 8"
        :dark="dark"
        pagination
        table-filter
        items-per-page-select
        sorter
      >
        <template #created_at="{item}">
          <td>
              {{formatDate(item.created_at)}}
          </td>     
        </template>
        <template #updated_at="{item}">
          <td>
              {{formatDate(item.updated_at)}}
          </td>     
        </template>
        <template #action="{item}">
          <td>
             <button class="btn btn-sm btn-outline-secondary" @click.prevent="openModalPlantingTrees(item)">
                <i class="bi bi-card-text text-primary"></i>
             </button>
          </td>
        </template>
        <template #no_students="{item}">
            <td>
                {{addComma(item.no_students)}}
            </td>
        </template>
          <template #total_target_trees_to_be_planted="{item}">
            <td>
                {{addComma(item.app_trees_per_student)}}
            </td>
        </template>
        <!-- <template #HEIs="{item}">
            <td>
              {{ item.name }}
            </td>
        </template> -->
        <template #remaining_trees_to_be_planted="{item}">
            <td>
              {{ addComma((item.app_trees_per_student) - item.planted_total_trees) }}
            </td>
        </template>
        <!-- <template #total_trees_planted="{item}">
            <td>
              {{ getAllTotalTreesPlanted(item.tree_id) }}
            </td>
        </template> -->
      </CDataTable>

    </CCardBody>
  </CCard>
</template>

<script>
export default {
  
  name: 'Table',
  totalPlanted: '',
  props: {
    list_heis: Array,
    totalNumberStudents: '',
    totalNumberAppTreesPerStudent: '',
    fields: {
      type: Array,
      default () {
        return ['HEIs','no_students','total_target_trees_to_be_planted', 'planted_total_trees','remaining_trees_to_be_planted','action']
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  mounted() {
    console.log("ssss", this.totalNumberStudents);
  },
  methods: {

    formatDate(date) {
      return moment
        .utc(date, "YYYY-MM-D hh:mm:ss")
        .locale("en-us")
        .local()
        .format("MMM D YYYY hh:mm A");
    },
    openModalPlantingTrees(item) {
      this.$emit('PlantingTreesModal', item);
    },
    roundOf(per) {
      let roundOf = Math.round(per * 100) / 100

      return roundOf;
    },
    addComma(amount) {
      let num_parts = amount.toString().split(".");
      num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      return num_parts.join(".");
    },
    getAllTotalTreesPlanted(tree_id) {
              var id = 'hello0';
              const response = axios.get('api/get-total-planted-trees-per-hei/' + tree_id)
              .then( response => {                              
                  console.log(id);
                  id = 'dli na hello';
                   console.log(id);
                  id = response.data;
               });

               console.log('outside: ' + id);
          
                
                 

           
                      
    }

  }
}
</script>
