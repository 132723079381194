<template>
    <div>
    <CRow>
      <CCol>
        <CCard>
          <CCardHeader>
            <CIcon name="cil-justify-center"/>
            <strong> Planting Trees</strong>
            <div class="card-header-actions">
              <a 
                href="#" 
                class="card-header-action" 
                rel="noreferrer noopener" 
                target="_blank"
              >
                <small class="text-muted">PT</small>
              </a>
            </div>
          </CCardHeader>
          <CCardBody >
                 <div class="row">
                      <div class="form-group ml-4">
                            <button class="ml-auto mr-3 btn btn-primary btn-sm" style="height:27px" @click="addSchoolModal">Add HEI</button>
                      </div>
                  </div>
                  <div class="row">
                     <span class="ml-4 mb-2">Total No. of Students: <span class="font-weight-bold"> {{ addComma(totalNumberStudents)}} </span></span>
                      <span class="ml-4 mb-2">Total target trees to be planted: <span class="font-weight-bold">{{ addComma(totalNumberAppTreesPerStudent) }}</span></span>
                      <span class="ml-4 mb-2">Total Trees Planted: <span class="font-weight-bold">{{ addComma(totalTreesPlanted) }}</span></span>

                  </div>
                <CRow>
                  <CCol lg="12">
                        <planting-heis :list_heis="list_heis" :totalNumberStudents="totalNumberStudents" :totalNumberAppTreesPerStudent="totalNumberAppTreesPerStudent" @PlantingTreesModal="openTreesModal">
                            <template #header>
                                <div class="card-header-actions">
                                <a 
                                    href="https://coreui.io/vue/docs/components/nav" 
                                    class="card-header-action" 
                                    rel="noreferrer noopener" 
                                    target="_blank"
                                >
                                    <small class="text-muted">List</small>
                                </a>
                                </div>
                            </template>  
                        </planting-heis>
                  </CCol>
                </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

        <!------------ Start- Add Plan Modal ------------>
    <div id="add-school" class="modal fade" >
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Schhol</h5>

            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form @submit.prevent="submitSchool">
            <div class="modal-body">
                <div class="mb-3">
                <div class="row">
                    <div class="col-md-12">
                        <label class="mt-4 font-weight-bold">HEI's</label>
                        <select v-model="addSchool.heis" class="form-control">
                            <option v-for="(hei,i) in heis" :key="i" :value="hei.code">{{hei.name}}</option>
                        </select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                        <label class="mt-4 font-weight-bold">Email</label>
                        <input type="email" class="form-control" v-model="addSchool.email"/>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                        <label class="mt-4 font-weight-bold">Contact No.</label>
                        <input  type="number" class="form-control" v-model="addSchool.contact_no"/>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                        <label class="mt-4 font-weight-bold">No. Students</label>
                        <input  type="number" class="form-control" v-model="addSchool.no_students"/>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                        <label class="mt-4 font-weight-bold">Approximately 10 trees per student (total)</label>
                        <input  type="number" class="form-control" v-model="addSchool.app_trees_per_student"/>
                    </div>
                  </div>
                </div>
            </div>
            <!-- END OF MODAL-BODY -->
            <div class="modal-footer">
              <button class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button class="btn btn-primary" type="submit" v-if="!loading">Save</button>
              <button class="btn btn-primary" type="button" disabled v-if="loading">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Saving...
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!------------ End - Add Plan Modal ------------>

    <!------------ Start- View Tree Modal ------------>
    <div id="tree-card" class="modal fade">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">List of Tree Planted</h5>

            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
              <planting :p_trees="p_trees">
                  <template #header>
                   <span class="ml-4 mb-2">Total no. of trees planted: <span class="font-weight-bold"> {{ addComma(totalNoTreesPerHEI)}} </span></span>
                    <span class="ml-4 mb-2">Total remaining trees to be planted: <span class="font-weight-bold"> {{ addComma(AppTreesPerStudent - totalNoTreesPerHEI)}} </span></span>
                      <div class="card-header-actions">
                      <a 
                          href="https://coreui.io/vue/docs/components/nav" 
                          class="card-header-action" 
                          rel="noreferrer noopener" 
                          target="_blank"
                      >
                          <small class="text-muted">List</small>
                      </a>
                      </div>
                  </template>  
              </planting>
          <div class="modal-footer">
            <button class="btn btn-danger" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
    <!------------ End - View Tree Modal ------------>
  </div>
</template>

<script>
import PlantingHeis from './component/ListPlantingSchool.vue'
import Planting from './component/bySchoolTreesTable.vue'

export default {
  components: { PlantingHeis, Planting },
  data() {
    return {
      addSchool: {
          heis: '',
          no_students: '',
          app_trees_per_student: '',
          email: '',
          contact_no: ''
      },
      loading: false,
      heis: [],
      p_trees: [],
      list_heis: [],
      hei_trees: [],
      treeID: '',
      totalNumberStudents: '',
      totalNumberAppTreesPerStudent: '',
      totalTreesPlanted: '',
      totalNoTreesPerHEI: '',
      AppTreesPerStudent: ''
    }
  },
  mounted() {
   this.getSchool();
   this.getTotalTrees();
   this.getListHEIs();
  },
  methods: {

    // openTreesModal(plantingTrees) {

    //   this.hei_trees = { ...plantingTrees };
    //   console.log("hei treeesss", this.hei_trees);
    // //   $('#edit-disburse').modal('show');
    // },
    openTreesModal(plantingTrees) {
      this.treeID = plantingTrees.tree_id;
      this.AppTreesPerStudent = plantingTrees.app_trees_per_student;
      console.log("planting trees", plantingTrees);
      this.getPlantingTrees(this.treeID);
      $('#tree-card').modal('show');
    },
  addComma(amount) {
    let num_parts = amount.toString().split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return num_parts.join(".");
  },
  resetSchool() {
    this.addSchool = {
        heis: '',
        no_students: '',
        app_trees_per_student: '',
        email: '',
        contact_no: ''
    }
  },
    async getTotalTrees() {
        try {
            const response = await axios.get('api/get-total-trees-planted')
            .then((response) => {

                let total_trees = response.data.total_trees; 
                let len = total_trees.length;
                let totalTreesPlantd = 0;
                for(let i=0; i<len; i++) {
                  totalTreesPlantd = totalTreesPlantd + total_trees[i].no_trees;
                } 
                this.totalTreesPlanted = totalTreesPlantd;        
          })
        } catch(error) {
            console.log("err", error);
            alert('Something went wrong!');
        }
    },
  async getPlantingTrees(treeID) {
        try {
            const response = await axios.get('api/get-planting-trees-by-school/' + treeID)
            .then((response) => {

                this.p_trees = response.data.planting_trees;  
                // this.p_trees.shift();
                console.log("heis tree", this.p_trees);
                let len = this.p_trees.length;
                let totalNoTreesPrHEI = 0;
                for(let i=0; i<len; i++) {
                  totalNoTreesPrHEI = totalNoTreesPrHEI + this.p_trees[i].no_trees;
                } 
                this.totalNoTreesPerHEI = totalNoTreesPrHEI; 
            })
        } catch(error) {
            console.log("err", error);
            alert('Something went wrong!');
        }
    },
    addSchoolModal() {
      $('#add-school').modal('show');
    },
    async getSchool() {
          try {
              const response = await axios.get('api/get-heis')
              .then((response) => {
              this.heis = response.data.heis;  
              console.log("heis", this.heis);
 
              })
          } catch(error) {
              console.log("err", error);
              alert('Something went wrong!');
          }
      },
      async getListHEIs() {
          try {
              const response = await axios.get('api/get-list-heis')
              .then((response) => {
                this.list_heis = response.data.list_heis;  
                console.log("list heis", this.list_heis);
                
                let len = this.list_heis.length;
                let totalNumberStdents = 0;
                let totalNumberAppTreesPerStdent = 0;
                for(let i=0; i<len; i++) {
                  totalNumberStdents = totalNumberStdents + this.list_heis[i].no_students;
                  totalNumberAppTreesPerStdent = totalNumberAppTreesPerStdent + this.list_heis[i].app_trees_per_student;
                }
                this.totalNumberStudents = totalNumberStdents;
                this.totalNumberAppTreesPerStudent = totalNumberAppTreesPerStdent;
              })
          } catch(error) {
              console.log("err", error);
              alert('Something went wrong!');
          }
      },
    submitSchool() {
      this.loading = true;
      let formData = new FormData();
      formData.append('heis', this.addSchool.heis);
      formData.append('email', this.addSchool.email);
      formData.append('contact_no', this.addSchool.contact_no);
      formData.append('no_students', this.addSchool.no_students);
      formData.append('app_trees_per_student', this.addSchool.app_trees_per_student);
      axios
        .post('api/add-school', formData)
        .then((response) => {
          toastr.success(response.data.message);  
          $('#add-school').modal('hide');
          this.loading = false;
          this.resetSchool();
          this.getListHEIs();

        })
        .catch((response) => {
          console.log(response);
          alert('Something went wrong!');
        });
    },
  }
}
</script>

<style>

</style>